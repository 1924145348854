import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Bio from "../components/Bio";

export default function Home() {
    return (
        <Layout>
            <Seo
                title="404"
                keywords={[`prikshit`, `tekta`, `portfolio`, `404`]}
            />
            <Bio />
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
    );
}
